/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react";
import PropTypes from "prop-types";
import { useStaticQuery, graphql } from "gatsby";
import { PageProps } from "gatsby";

import { FormattedMessage, useIntl } from "gatsby-plugin-intl";
import { NormalizeStyles } from "./normalize";
import { StyledLayout, GlobalStyles } from "./style";
import { main_theme } from "./theme";
import { supportsUppercasing } from "../../helpers/rioti18n";
import { ThemeProvider } from "styled-components";

type Params = {
  children: JSX.Element[];
};

const Layout = ({ children }: Params) => {
  // Or you can use gatsby-plugin-intl to display translated strings.
  const intl = useIntl();

  // Load the main theme.
  const theme = {
    ...main_theme,
    // Uppercasing is not supported in some languages.
    supports_uppercasing: supportsUppercasing(intl.locale),
  };

  return (
    // Set the main theme for this layout. Themes can be used within styles.
    <ThemeProvider theme={theme}>
      {/* 
        NormalizaStyles is a set of global styles that reset browser assumptions
        so we can start from an homogeneous base.
      */}
      <NormalizeStyles />
      {/* Global styles for html, body, etc. */}
      <GlobalStyles />
      {/* Google Tag Manager (noscript) */}
      <noscript>
        <iframe
          src="https://www.googletagmanager.com/ns.html?id=GTM-KBF2RWH"
          height="0"
          width="0"
          style={{ display: "none", visibility: "hidden" }}
        ></iframe>
      </noscript>
      <StyledLayout>
        <main>{children}</main>
      </StyledLayout>
    </ThemeProvider>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
