import { createGlobalStyle, css } from "styled-components";
/* 
  font @import rules are not working with styled-component global 
  rules at this time, so we use css or Helmet to inject them as a global style.
*/
// export default = createGlobalStyle`
//   @import url("https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,400;0,600;0,700;1,400;1,600;1,700&display=swap");
//   @import url("https://lolstatic-a.akamaihd.net/awesomefonts/1.0.0/lol-fonts.css");
// `;
// We need this to load custom fonts.
import "./theme-fonts.css";

/** Colors */

export const tellstones_navy = "#374C68";
export const tellstones_offwhite = "#F7F3F0";
export const tellstones_red = "#D5363A";
export const tellstones_white = "#FFFFFF";
export const tellstones_black = "#000000";
export const rpg_btn_resting = "#FF4248";
export const color_rpg_btn_hover = "#D6383D";
export const rpg_btn_active = "#C23237";

/** Themes */

export const main_theme = {
  color_background: tellstones_offwhite,
  color_title: tellstones_navy,
  color_subtitle: tellstones_navy,
  color_heading_1: tellstones_navy,
  color_heading_2: tellstones_navy,
  color_heading_3: tellstones_navy,
  color_heading_4: tellstones_navy,
  color_heading_5: tellstones_navy,
  color_heading_6: tellstones_navy,
  color_accent: tellstones_navy,
  color_heading: tellstones_navy,
  color_text: tellstones_navy,
  color_text_dimmed: tellstones_navy,
  color_text_bold: tellstones_navy,
  color_text_accent: tellstones_navy,
  color_text_light: tellstones_white,
  tellstones_navy: "#374C68",
  tellstones_offwhite: "#F7F3F0",
  tellstones_red: "#D5363A",
  tellstones_white: "#FFFFFF",
  tellstones_black: "#000000",
  rpg_red_resting: "#FF4248",
  rpg_red_hover: "#D6383D",
  rpg_red_active: "#C23237",
  font_family_main: `"Spiegel", sans-serif`,
  font_family_alt: `"BeaufortforLOL-Bold", serif`,
  font_family_legal: `"FF Mark W05", sans-serif`,
  font_family_alt_italic: `"BeaufortforLOL-BoldItalic", serif`,
  supports_uppercasing: true,
};

/** Mixins */

// Main font, to use for most text.
export const StyleFontMain = css`
  font-family: ${(props) => props.theme.font_family_main};
  font-size: 16px;
  line-height: 155%;
  letter-spacing: -0.01em;
`;

// Alternate font, for accents.
export const StyleFontAlt = css`
  font-family: ${(props) => props.theme.font_family_alt};
  color: ${tellstones_navy};
`;

// Alternate font italicized, for accents.
export const StyleFontAltItalic = css`
  font-family: ${(props) => props.theme.font_family_alt_italic};
  color: ${tellstones_navy};
`;

// use this mix-in instead of `text-transform: uppercase`
export const TextTransformUppercase = css`
  text-transform: ${(props) => (props.theme.supports_uppercasing ? "uppercase" : "none")};
`;

// named sizes for screen widths.
export const size = {
  mobileS: "320px",
  mobileM: "375px",
  mobileL: "425px",
  mobileXL: "600px",
  tablet: "768px",
  laptop: "1024px",
  laptopL: "1440px",
  desktop: "2560px",
};

// Named devices for media queries.
export const device = {
  mobileS: `(min-width: ${size.mobileS})`,
  mobileM: `(min-width: ${size.mobileM})`,
  mobileL: `(min-width: ${size.mobileL})`,
  mobileXL: `(min-width: ${size.mobileXL})`,
  tablet: `(min-width: ${size.tablet})`,
  laptop: `(min-width: ${size.laptop})`,
  laptopL: `(min-width: ${size.laptopL})`,
  desktop: `(min-width: ${size.desktop})`,
  desktopL: `(min-width: ${size.desktop})`,
};
