import styled, { createGlobalStyle } from "styled-components";
import { StyleFontMain, size } from "./theme";
import buttonResting from "../../images/button-resting.svg";
import buttonHover from "../../images/button-hover.svg";
import buttonClick from "../../images/button-click.svg";

/**
 * Global styles let you style outer tags like html or body.
 * As a general rule, try to keep global styles to a minimum since these
 * can silently affect any other styles.
 * https://styled-components.com/docs/api#createglobalstyle
 */
export const GlobalStyles = createGlobalStyle<{ theme: any }>`
  html {
    background: ${(props) => props.theme.color_background};
  }
`;

/**
 * Styled components let you define styles for them or any children.
 * https://styled-components.com/docs/basics
 */
export const StyledLayout = styled.div`
  width: 100%;
  height: 100%;
  overflow: hidden;

  color: ${(props) => props.theme.color_text};

  margin: 0;
  max-width: 960;
  padding: 0;
  font-size: 16px;
  ${StyleFontMain}

  /* You can set some global styles for children elements here */

  h1 {
    font-family: ${(props) => props.theme.font_family_alt_italic};
    font-size: 55px;
    line-height: 105%;
    letter-spacing: 0.01em;
    text-transform: uppercase;
    color: ${(props) => props.theme.color_heading_1};
  }

  h5 {
    font-family: ${(props) => props.theme.font_family_alt};
    font-size: 23px;
    line-height: 27px;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    color: ${(props) => props.theme.color_heading_1};
  }

  p {
    margin: 1rem 0;
  }

  strong {
    color: ${({ theme }) => theme.color_text_bold};
    font-weight: bold;
  }

  em {
    color: ${({ theme }) => theme.color_text_accent};
    font-style: italic;
  }

  a {
    color: ${({ theme }) => theme.color_text_accent};
    text-decoration: none;
    &:visited {
      color: ${({ theme }) => theme.color_text_dimmed};
    }
  }

  .toc-dark {
    font-family: ${(props) => props.theme.font_family_legal};
    color: ${(props) => props.theme.tellstones_navy};
    font-size: 8.192px;
    line-height: 130%;
    letter-spacing: 0.08em;
    text-transform: uppercase;
    font-weight: bold;
  }

  .toc-light {
    font-family: ${(props) => props.theme.font_family_legal};
    color: ${(props) => props.theme.tellstones_white};
    font-size: 8.192px;
    line-height: 130%;
    letter-spacing: 0.08em;
    text-transform: uppercase;
    font-weight: bold;
  }

  .tellstones-button {
    font-family: ${(props) => props.theme.font_family_alt};
    color: ${(props) => props.theme.tellstones_navy};
    font-size: 20px;
    line-height: 23px;
    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    padding: 16px 45px;
    margin: 36px auto auto;
    background-image: url(${buttonResting});
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;

    &:hover {
      color: ${(props) => props.theme.tellstones_offwhite};
      background-image: url(${buttonHover});
    }

    &:disabled {
      color: ${(props) => props.theme.tellstones_navy};
      background-image: url(${buttonResting});
    }

    &:active {
      color: ${(props) => props.theme.tellstones_black};
      background-image: url(${buttonClick});
    }

    @media (max-width: ${size.mobileXL}) {
      margin: 24px auto 80px;
    }
  }

  .rpg-button {
    font-family: ${(props) => props.theme.font_family_main};
    color: ${(props) => props.theme.color_text_light};
    font-size: 14px;
    line-height: 105%;
    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    background: ${(props) => props.theme.rpg_red_resting};
    border-radius: 75px;
    padding: 14px 52px;
    margin: 43px auto 0;

    &:hover {
      background: ${(props) => props.theme.rpg_red_hover};
    }

    &:disabled {
      background: ${(props) => props.theme.rpg_red_resting};
      opacity: 0.3;
    }

    &:active {
      background: ${(props) => props.theme.rpg_red_active};
    }
  }

  .subheader-dark {
    font-family: ${(props) => props.theme.font_family_main};
    color: ${(props) => props.theme.tellstones_navy};
    font-size: 16px;
    line-height: 155%;
    text-align: center;
    letter-spacing: 0.25em;
    text-transform: uppercase;
    font-weight: bold;
  }

  .subheader-light {
    font-family: ${(props) => props.theme.font_family_main};
    color: ${(props) => props.theme.tellstones_white};
    font-size: 16px;
    line-height: 155%;
    text-align: center;
    letter-spacing: 0.25em;
    text-transform: uppercase;
  }

  .row {
    flex: 1;
    display: flex;
    flex-direction: row;
  }

  .column {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: auto;
  }

  .center {
    text-align: center;
    justify-content: center;
    margin: auto;
  }

  .text-block {
    max-width: 447px;
  }

  .label-light {
    font-family: ${(props) => props.theme.font_family_main};
    color: ${(props) => props.theme.tellstones_white};
    font-size: 11px;
    line-height: 155%;
    letter-spacing: 0.15em;
    text-transform: uppercase;
    font-weight: bold;
  }

  .label-dark {
    font-family: ${(props) => props.theme.font_family_main};
    color: ${(props) => props.theme.tellstones_navy};
    font-size: 11px;
    line-height: 155%;
    letter-spacing: 0.15em;
    text-transform: uppercase;
    font-weight: bold;
  }

  input[type="text"].textfield-dark {
    background: rgba(196, 196, 196, 0.1);
    border: 3px solid #374c68;
    border-radius: 0;
    box-sizing: border-box;
    box-shadow: inset 0px 2px 4px rgba(0, 0, 0, 0.35);
    min-width: 200px;
    width: 100%;
    height: 68px;
    padding: 10px;
    font-family: ${(props) => props.theme.font_family_main};
    color: ${(props) => props.theme.tellstones_navy};
    letter-spacing: 0.15em;
  }

  input[type="text"].textfield-light {
    border-radius: 0;
    border: 1px solid #262626;
    box-sizing: border-box;
    box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.75);
    min-width: 200px;
    width: 100%;
    height: 68px;
    padding: 10px;
    margin-bottom: 22px;
    background: rgba(196, 196, 196, 0.1);
    font-family: ${(props) => props.theme.font_family_main};
    color: ${(props) => props.theme.tellstones_white};
    letter-spacing: 0.15em;
  }

  ::-ms-clear {
    display: none;
  }
`;
